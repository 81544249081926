<template>
    <div class="stepOne">
        <div class="success-icon">
            <van-icon color='#00DE93' size="80px" name="checked" />
            <div>审核成功</div>
        </div>
        <div style="padding-bottom:50px">
            <!-- <van-button round block type="primary" :url="['https://view.officeapps.live.com/op/view.aspx?src=' + applyInfo.contract_link]">合同已发送至邮箱</van-button> -->
            <van-button round block type="primary">合同已发送至邮箱({{applyInfo.email}})</van-button>
        </div>
    </div>
</template>

<script>
import { Tabbar, TabbarItem, Cell, CellGroup} from 'vant'
    export default {
        name:'stepTwo',
        data() {
            return {}
        },
        props:{
            applyInfo:Object
        },
        components: {
            [Tabbar.name]: Tabbar,
            [TabbarItem.name]: TabbarItem,
            [Cell.name]: Cell,
            [CellGroup.name]: CellGroup,
        },
    }
</script>

<style lang="less" scoped>
    .success-icon{
        text-align: center;
        background-color: #fff;
        padding: 40px 0;
        div{
            font-size: 16px;
            font-weight: bold;
            color: #00DE93;
        }
    }
    .report-btn{
        width: 100%;
        height: 50px;
        background-color: #00DE93;
        position: fixed;
        bottom: 0;
        text-align: center;
        line-height: 50px;
        color: #fff;
    }
    .stepOne{
        .van-cell{
            display: flex
        }
    }
</style>