<template>
    <div class="stepOne">
        <div class="success-icon">
            <van-icon color='#f8ac59' size="80px" name="warning-o" />
            <div>合同待审核</div>
        </div>
        <van-cell-group>
            <van-cell v-for="(item,index) in list" :key="index" :title="item.label" :value="item.value" />
        </van-cell-group>
        <div>
            <van-button @click="toStep" type="primary" block>编辑信息</van-button>
        </div>
    </div>
</template>

<script>
import { Tabbar, TabbarItem, Cell, CellGroup} from 'vant'
    export default {
        name:'stepOne',
        data() {
            return {
                list:[
                    {label:'订单号码',value:'',dataIndex:'out_trade_no'},
                    {label:'学员名称',value:'',dataIndex:'student_name'},
                    {label:'证件号码',value:'',dataIndex:'credential_number'},
                    {label:'收费名称',value:'',dataIndex:'course_cate_name'},
                    {label:'课程顾问',value:'',dataIndex:'course_consultant_name'},
                    {label:'邮箱地址',value:'',dataIndex:'email'},
                    {label:'申请时间',value:'',dataIndex:'apply_time'},
                ]
            }
        },
        props:{
            applyInfo:Object
        },
        components: {
            [Tabbar.name]: Tabbar,
            [TabbarItem.name]: TabbarItem,
            [Cell.name]: Cell,
            [CellGroup.name]: CellGroup,
        },
        mounted(){
            this.list.forEach(item=>{
                item.value = this.applyInfo[item.dataIndex]
            })
        },
        methods: {
            toStep() {
                this.$emit('changeStep')
            }
        },
        
    }
</script>

<style lang="less" scoped>
    .success-icon{
        text-align: center;
        background-color: #fff;
        padding: 40px 0;
        div{
            font-size: 16px;
            font-weight: bold;
            color: #f8ac59;
        }
    }
    .report-btn{
        width: 100%;
        height: 50px;
        background-color: #00DE93;
        position: fixed;
        bottom: 0;
        text-align: center;
        line-height: 50px;
        color: #fff;
    }
    .stepOne{
        .van-cell{
            display: flex
        }
        .van-cell__value{
            flex: 2;
        }
    }
</style>