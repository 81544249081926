<template>
    <div>
        <div class="agreement ql-editor" v-html="agreementHtml"></div>
        <!-- <div @click="toBack" class="agreement-btn">
            <van-button round block type="info">
                返回上一页
            </van-button>
        </div> -->
    </div>
</template>

<script>
    import 'quill/dist/quill.core.css'
    import 'quill/dist/quill.snow.css'
    import 'quill/dist/quill.bubble.css'
    export default {
        name:'agreement',
        data() {
            return {
                agreementHtml: ''
            }
        },
        mounted(){
            this.$api.premium_agreement()
            .then(res=>{
                this.agreementHtml = res.data.agreement
            })
            .catch(err=>{
                console.log(err)
            })
        },
        methods: {
            toBack() {
                this.$router.go(-1)
            }
        },
    }
</script>
<style lang="less" scoped>
    .agreement{
        padding: 0 10px;
        padding-top: 20px;
        padding-bottom: 50px;
    }
    .ql-editor{
        line-height: 1.7;
    }
    .agreement-btn{
        position: fixed;
        bottom: 20px;
        width: 90%;
        left: 5%;
    }
</style>